import React from "react";
import {
  IoIosAdd,
  IoIosRadioButtonOff,
  IoIosCheckmarkCircleOutline,
  IoIosCheckmarkCircle,
  IoIosCloseCircle,
  IoMdColorPalette,
  IoMdSettings,
  IoMdEye,
  IoMdEyeOff,
  IoMdTrash
} from "react-icons/io";
import { TwitterPicker } from "react-color";
import './App.css';


class ToDoItem extends React.Component {
  render() {
    if (this.state.done == true) {
      return <a className="panel-block is-hidden"> </a>;
    }

    return (
      <a
        className="panel-block"
        onMouseDown={this.clickState}
        onMouseOut={this.mouseOut}
        onMouseOver={this.hoverState}
        onMouseUp={this.clickRelease}
      >
        <span className="panel-icon">
          {this.state.hover == true ? (
            this.state.status == 0 ? (
              <IoIosCheckmarkCircleOutline />
            ) : (
                <IoIosCheckmarkCircle />
              )
          ) : this.state.status == 0 ? (
            <IoIosRadioButtonOff />
          ) : (
                <IoIosCheckmarkCircle />
              )}
        </span>
        {this.props.itemName}
      </a>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      status: props.status,
      hover: false,
      done: props.status == 1
    };

    this.clickState = this.clickState.bind(this);
    this.hoverState = this.hoverState.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.clickRelease = this.clickRelease.bind(this);
  }

  hoverState() {
    this.setState({ hover: true });
  }

  mouseOut() {
    this.setState({ hover: false });
  }

  clickRelease() {
    this.setState({ done: true, status: 1 });
  }

  clickState() {
    // if (this.state.status == 1) { this.props.deleteme()
    // }
    if (this.state.status < 1) {
      this.setState({ status: this.state.status + 1 }, () => {
        this.props.updateData(this.props.index, this.state.status)
      });
    }
  }
}


class Lists extends React.Component {
  constructor(props) {
    super(props);

    this.deleteKey = this.deleteKey.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.addToDoList = this.addToDoList.bind(this);
    this.mouseDrag = this.mouseDrag.bind(this);
    this.mouseDrop = this.mouseDrop.bind(this);
    this.updateStatus = this.updateStatus.bind(this);

    this.state = {
      inputBox: true,
      completedItemIndex: [],
      input: "",
      data: this.props.data,
      preference: this.props.preference,
      clientX: this.props.preference.clientX,
      clientY: this.props.preference.clientY
    };

    this.offsets = null;
  }

  mouseDrag(initialEvent) {
    let xOffset = initialEvent.clientX;
    let yOffset = initialEvent.clientY;

    if (this.offsets == null) {
      this.offsets = {
        x: xOffset - this.state.clientX,
        y: yOffset - this.state.clientY
      };
    }

    let that = this;
    this.mouseMoveEvent = function (e) {
      that.setState({
        clientX: e.clientX - (that.offsets?.x ?? 0),
        clientY: e.clientY - (that.offsets?.y ?? 0)
      }, () => {
        that.props.updatePosition(that.props.index, that.state.clientX, that.state.clientY)
      });
    };
    window.addEventListener("mousemove", this.mouseMoveEvent);

    window.addEventListener("mouseup", that.mouseDrop);
  }

  componentDidUpdate(){
    
  }

  mouseDrop() {
    window.removeEventListener("mousemove", this.mouseMoveEvent, false);
    this.mouseMoveEvent = null;
    this.offsets = null;

    window.removeEventListener("mouseup", this.mouseDrop, false);
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.mouseMoveEvent, false);
    this.mouseMoveEvent = null;

    window.removeEventListener("mouseup", this.mouseDrop, false);
  }

  deleteKey(index) { }

  inputChange(e) {
    this.setState({ input: e.target.value });
  }

  updateStatus(index, status){
    let newData = this.state.data;

    newData[index].status = status;

    this.setState({data: newData}, () => {
      let that = this;
      this.props.updateData(this.props.index, that.state.data)
    })
  }

  addToDoList() {
    if (this.state.input == "") {
      return;
    } else {
      this.setState({
        data: this.state.data.concat([{message: this.state.input, status: 0}]),
        input: ""
      }, () => {
        let that = this;
        this.props.updateData(this.props.index, that.state.data)
      });
    }
  }

  render() {
    var items = this.state.data.map((i, k) => {
      return (
        <ToDoItem
          itemName={i.message}
          status={i.status}
          index={k}
          updateData={this.updateStatus}
          deleteme={a => {
            return this.deleteKey(k);
          }}
        />
      );
    });

    var listStyle = {
      position: "fixed",
      top: this.state.clientY + "px",
      left: this.state.clientX + "px",
      width: "265px"
    };

    return (
      <nav
        className={`panel ${this.props.preference.hidden ? 'is-hidden' : ''} has-background-white has-text-black is-unselectable`}
        style={listStyle}
      >
        <p
          className="panel-heading"
          style={{ backgroundColor: this.props.preference.color, display: "flex", justifyContent: "space-between", padding: "0px" }}

        >

          <span onMouseDown={this.mouseDrag} style={{ flexGrow: 1, padding: "0.75em 1em" }}> {this.props.name} </span>
          <span style={{ display: "flex", alignItems: "center", padding: "20px" }} onClick={this.props.hide}> <IoIosCloseCircle /> </span>
        </p>

        {items}

        <div
          className="field has-addons"
          style={{
            justifyContent: "center",
            paddingTop: "10px",
            paddingBottom: "10px"
          }}
        >
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="New Item"
              value={this.state.input}
              onChange={this.inputChange}
              onKeyUp={e => {
                if (e.keyCode == 13) {
                  this.addToDoList();
                }
              }}
            />
          </div>
          <div className="control">
            <a className="button is-info" onClick={this.addToDoList}>
              <span className="icon is-large has-text-white">
                <IoIosAdd />
              </span>
            </a>
          </div>
        </div>
      </nav>
    );
  }
}
class SideBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listOfLists: [

      ],
      addListInput: "",
      version: "1.1"
    };


    this.createList = this.createList.bind(this);
    this.handleListInputChange = this.handleListInputChange.bind(this);
    this.eyeClick = this.eyeClick.bind(this);
    this.settingsClick = this.settingsClick.bind(this);
    this.deleteList = this.deleteList.bind(this);
    this.renameList = this.renameList.bind(this);
    this.recolorList = this.recolorList.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.updateData = this.updateData.bind(this);
  }


 

  componentDidMount() {
    var a = window.localStorage.getItem("data");
    if(a && JSON.parse(a)["version"] != this.state.version){
      console.log("Incompatible data storage system!")
      window.localStorage.clear();
      return;
    }
    if(a){
      this.setState(JSON.parse(a))
    }
  }

  componentDidUpdate(props) {
    window.localStorage.setItem("data", JSON.stringify(this.state))
  }

  eyeClick(index) {
    let newlistoflists = this.state.listOfLists;
    newlistoflists[index].preferences.hidden = !newlistoflists[index].preferences.hidden

    this.setState({ listOfLists: newlistoflists })
  }

  settingsClick(index) {
    let newlistoflists = this.state.listOfLists;
    newlistoflists[index].preferences.preferenceShown = !newlistoflists[index].preferences.preferenceShown

    this.setState({ listOfLists: newlistoflists })
  }

  handleListInputChange(event) {
    let inputValue = event.target.value;

    this.setState({ addListInput: inputValue });

  }

  createList() {
    let nameoflist = this.state.addListInput;


    // If it doesn't exist or is not null, create it
    if (this.state.listOfLists[nameoflist] == undefined && nameoflist != "") {
      // List of lists that contains todolists
      var list = { name: nameoflist, data: [], preferences: { color: "#ededed", hidden: false, clientX: 0, clientY: 0} }

      var newlistoflists = this.state.listOfLists;

      newlistoflists.push(list);

      this.setState({ listOfLists: newlistoflists, addListInput: "" });

    }


  }


  deleteList(index) {
    let newlistoflists = []
    for(var i = 0; i < this.state.listOfLists.length; i++){
      if(i == index){}
      else {
        newlistoflists.push(this.state.listOfLists[i]);
      }
    }


    this.setState({ listOfLists: newlistoflists })
  }


  renameList(index, newname) {
    let newlistoflists = this.state.listOfLists

    newlistoflists[index].name = newname;

    this.setState({ listOfLists: newlistoflists })
  }

  updateData(index, data){
    let newlistoflists = this.state.listOfLists

    newlistoflists[index].data = data;

    this.setState({ listOfLists: newlistoflists })
  }

  recolorList(index, newcolor) {
    let newlistoflists = this.state.listOfLists

    newlistoflists[index].preferences.color = newcolor;

    this.setState({ listOfLists: newlistoflists })
  }

  updatePosition(index, clientx, clienty){
    let newlistoflists = this.state.listOfLists

    if(newlistoflists[index]){
      newlistoflists[index].preferences.clientX = clientx;
      newlistoflists[index].preferences.clientY = clienty;
    }
    this.setState({ listOfLists: newlistoflists })

  }



  render() {

    var panelList = this.state.listOfLists.map((list, key) => {
      return <Lists key={key} hide={() => { this.eyeClick(key) }} index={key} updatePosition={this.updatePosition} updateData={this.updateData} name={list.name} data={list.data} preference={list.preferences} />

    });

    var settingsBarStyle = {
      backgroundColor: "white",
      position: "absolute",
      borderRadius: "8px",
      padding: "10px",
      border: "1px solid #ededed",
    }


    var RenameInput = (props) => {
      var [newName, setNewName] = React.useState("");
      return <div class="field is-grouped">
        <input className="input" value={newName} onChange={(text) => { setNewName(text.target.value) }} style={{ marginTop: "5px" }} type="text" placeholder="Rename" />
        <div class="buttons">
          <button className="button is-info" style={{ marginLeft: "10px", marginTop: "5px" }} onClick={() => { this.renameList(props.index, newName) }}> Apply</button>
        </div>

      </div>
    }

    var Settingsbar = (props) => {
      return <div className="settings" style={settingsBarStyle}>

        <TwitterPicker triangle="hide:" colors={['#ff5656', '#FF6900', '#FCB900', '#f9ee73', '#7BDCB5', '#00D084', '#63AFAE', '#8ED1FC', '#0693E3', '#687BBE', '#ae48e7', '#ea4b74', '#f07394', '#F78DA7', '#ABB8C3', '#EDEDED']} onChangeComplete={(info) => { this.recolorList(props.index, info.hex) }} />

        <RenameInput index={props.index} />




        <div class="buttons">
          <button className="button is-danger" style={{ flexGrow: "1" }} onClick={() => { this.deleteList(props.index) }}>Delete</button>
        </div>

      </div>
    }

    var sideBarList = this.state.listOfLists.map((list, index) => {
      return <a key={index} className="panel-block" style={{ paddingLeft: "20px", display: "flex", justifyContent: "space-between" }}>
        <span className="panel-icon">
          {list.preferences.hidden ? <IoMdEyeOff onClick={() => { this.eyeClick(index) }} /> : <IoMdEye onClick={() => { this.eyeClick(index) }} />}

        </span>
        <span style={{ flexGrow: 1 }}> {list.name} </span>
        <span className="panel-icon">
          <IoMdSettings onClick={() => { this.settingsClick(index) }} />
          {list.preferences.preferenceShown ? <Settingsbar index={index} /> : false}
        </span>
      </a>

    })



    return (
      <React.Fragment>
        <div
          className="has-background-white has-text-black is-unselectable"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            borderRadius: "8px"
          }}
        >
          <nav
            className="has-background-white has-text-black is-unselectable"
            style={{
              paddingLeft: "0px",
              paddingTop: "0px",
              paddingBottom: "-20px",
              borderRadius: "8px"
            }}
          >
            <p className="panel-heading">Lists</p>
            {sideBarList}
          </nav>

          <div
            className="field has-addons"
            style={{
              justifyContent: "center", padding: "10px"
            }}
          >
            <div className="control">
              <input className="input" value={this.state.addListInput} type="text" placeholder="New List" onChange={this.handleListInputChange} onKeyUp={(e) => {
                if (e.keyCode == 13) {
                  this.createList()
                }

              }} />
            </div>
            <div className="control">
              <a className="button is-info" onClick={this.addToDoList}>
                <span onClick={this.createList} className="icon is-large has-text-white">
                  <IoIosAdd />
                </span>
              </a>
            </div>
          </div>
        </div>
        {panelList}
      </React.Fragment>
    );
  }
}



var ColorPicker = () => {
  return (


    <div class="has-background-white ColorPicker">
      <TwitterPicker triangle="hide" />
      <div class="buttons">
        <input class="input" type="text" placeholder="Rename" />
        <button class="button is-danger has-text-weight-semibold" style={{ flexGrow: 1 }}>Delete List <span class="is-small"><IoMdTrash /></span></button>
      </div>
    </div>





  );
};


function HomePage() {
  return (
    <section className="hero is-success is-fullheight">
      <div className="hero-body" style={{ alignItems: "stretch" }}>
        <SideBar />
      </div>
    </section>
  );
}

export default HomePage;
